@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #18181b;
  height: 100vh;
}

body {
  height: 100% !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mapboxgl-popup-content {
  background-color: #18181b !important;
  color: white;

  width: 280px;
  border: 1px solid #353539;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #18181b !important;
}

.mapboxgl-popup-close-button {
  color: white;
  padding: 0.5rem;
}

:root {
  --toastify-color-dark: #27272a !important;
  --toastify-color-progress-info: #3b81f6 !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.form-control {
  background-color: #323235;
  color: white;
  border: none;
  height: 100%;
  width: 100%;
}

.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  color: #f2f2f2;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #323235 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #18181b !important;
}
